var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{attrs:{"id":"principal"}},[_c('div',{staticClass:"contenedor-portada"},[_c('video',{staticClass:"portada",attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":`${_setup.cdn_archivos_dominio}/portada/${
          _setup.esMovil ? 'movil' : 'energia'
        }.webm`,"type":"video/webm"}})]),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"contenedor ancho-fijo m-y-10"},[_c('div',{staticClass:"flex flex-contenido-separado flex-columnas-100-al-simplificar"},[_c('div',{staticClass:"columna-8-esc m-b-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo",attrs:{"href":`${_setup.dominio}planeas`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_dominio}/tarjetas/planeas.png`}})]),_vm._m(2)])]),_c('div',{staticClass:"columna-8-esc m-b-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo",attrs:{"href":`${_setup.dominio}sargazo`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_dominio}/tarjetas/sargazo.png`}})]),_vm._m(3)])]),_c('div',{staticClass:"columna-8-esc m-b-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo",attrs:{"href":`${_setup.url_gema}/visualizador?seccion=energia-y-cambio-climatico`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_dominio}/tarjetas/eni-energia-gema.png`}})]),_vm._m(4)])]),_c('div',{staticClass:"columna-8-esc m-b-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo",attrs:{"href":`${_setup.ambiente_eni}/radiombligo?tematica=Energía y cambio climático`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_ldn}/radio-ombligo/ciencia-para-ninas-y-ninos.png`}})]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('div',{staticClass:"contenedor ancho-lectura"},[_c('h1',{staticClass:"titulo-eni texto-centrado texto-color-1"},[_vm._v(" Energía y Cambio Climático ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contenedor ancho-lectura m-y-10"},[_c('h2',{staticClass:"texto-centrado m-y-8-esc m-y-4-mov m-x-auto"},[_vm._v(" ENI Energía y Cambio Climático ")]),_c('p',{staticClass:"texto-alto m-y-8-esc m-y-4-mov"},[_vm._v(" El Ecosistema Nacional Informático de Energía y Cambio Climático ofrece un espacio de acceso abierto para el análisis de datos y visualización de información, generada en el marco del Programa Nacional Estratégico (Pronaces) de Energía y Cambio Climático. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Plataforma Nacional Energía, Ambiente y Sociedad (Planeas) ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Arribo del Sargazo Pelágico a las Costas Mexicanas ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Explora Capas del Tema Energía y Cambio Climático ")]),_c('hr'),_c('p',{staticClass:"subtitulo-capitulo texto-color-1"},[_vm._v(" Gestor de mapas (Gema) ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Ciencia para niñas y niños ")])])])
}]

export { render, staticRenderFns }