import { render, staticRenderFns } from "./PaginaInicio.vue?vue&type=template&id=ae8e7ee6&scoped=true"
import script from "./PaginaInicio.vue?vue&type=script&setup=true&lang=js"
export * from "./PaginaInicio.vue?vue&type=script&setup=true&lang=js"
import style0 from "./PaginaInicio.vue?vue&type=style&index=0&id=ae8e7ee6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae8e7ee6",
  null
  
)

export default component.exports