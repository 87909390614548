<script setup>
import { computed, onBeforeUnmount, onMounted } from 'vue'

function calHeight() {
  const innerheight = window.innerHeight / 100
  document
    .querySelector(':root')
    .style.setProperty('--vh', `${innerheight.toString()}px`)
}

calHeight()

onMounted(() => {
  window.addEventListener('resize', calHeight)
})

onBeforeUnmount(() => {
  window.addEventListener('resize', calHeight)
})

const esMovil = computed(() => Boolean(window.innerWidth < 768))

const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS
const url_gema = process.env.VUE_APP_GEMA
const dominio = process.env.VUE_APP_ENI_DOMAIN + process.env.VUE_APP_BASE_ROUTE
const ambiente_eni = process.env.VUE_APP_ENI_LDN
const cdn_archivos_ldn = cdn_archivos_dominio.replace(
  '/energia/index',
  '/landing'
)
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <video
        class="portada"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="`${cdn_archivos_dominio}/portada/${
            esMovil ? 'movil' : 'energia'
          }.webm`"
          type="video/webm"
        />
      </video>

      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-lectura">
            <h1 class="titulo-eni texto-centrado texto-color-1">
              Energía y Cambio Climático
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">
        ENI Energía y Cambio Climático
      </h2>

      <p class="texto-alto m-y-8-esc m-y-4-mov">
        El Ecosistema Nacional Informático de Energía y Cambio Climático ofrece
        un espacio de acceso abierto para el análisis de datos y visualización
        de información, generada en el marco del Programa Nacional Estratégico
        (Pronaces) de Energía y Cambio Climático.
      </p>
    </div>

    <div class="contenedor ancho-fijo m-y-10">
      <div
        class="flex flex-contenido-separado flex-columnas-100-al-simplificar"
      >
        <div class="columna-8-esc m-b-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${dominio}planeas`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/planeas.png`" />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Plataforma Nacional Energía, Ambiente y Sociedad (Planeas)
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-b-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${dominio}sargazo`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/sargazo.png`" />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Arribo del Sargazo Pelágico a las Costas Mexicanas
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-b-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${url_gema}/visualizador?seccion=energia-y-cambio-climatico`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_dominio}/tarjetas/eni-energia-gema.png`"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Explora Capas del Tema Energía y Cambio Climático
                </p>
                <hr />
                <p class="subtitulo-capitulo texto-color-1">
                  Gestor de mapas (Gema)
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-b-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${ambiente_eni}/radiombligo?tematica=Energía y cambio climático`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_ldn}/radio-ombligo/ciencia-para-ninas-y-ninos.png`"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Ciencia para niñas y niños
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.contenedor-portada {
  .titulo-eni {
    max-width: 700px;
    font-size: 4rem;
  }
}

.tarjeta-hipervinculo-externo {
  min-height: 320px;
  .fondo-imagen {
    img {
      width: 100%;
    }
  }
}
</style>
